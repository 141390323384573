import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import SEO from "../utils/SEO";
import SmartText from "../utils/TextHandler";
import Link from "../components/Global/Link";

// ====

function trimSlash(path) {
  return path.replace(/\/$/, "");
}

const NotFound = (props) => {
  const { data } = props;
  let doc;
  if (data) {
    doc = JSON.parse(data.index.content);
  }
  const openGraph = {
    type: "website",
    title: "Not Found",
    description: doc.fourohfour,
    noIndex: true,
  };

  return (
    <>
      <SEO openGraph={openGraph} />
      404 fools
    </>
  );
};

export default NotFound;

// ====

export const QUERY = graphql`
  query NotFoundDefaultQuery {
    index: storyblokEntry(slug: { eq: "settings" }) {
      content
    }
  }
`;

NotFound.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

NotFound.defaultProps = {
  data: null,
};
